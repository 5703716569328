import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer" style={{ left: "0px" }}>
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              © {new Date().getFullYear()} IWA - International Water Association
            </Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                To find out more about the event, please visit
                <a
                  className="mx-1"
                  href="https://digital.worldwatercongress.org"
                  target="_blank"
                >
                  digital.worldwatercongress.org
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
