import React, { useEffect, useState } from "react";
import VideoPlayer from "./VideoPlayer";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardSubtitle,
  Collapse,
  CardFooter,
  Row,
} from "reactstrap";

const Presentations = ({ session, accordianOpen }) => {
  return (
    <Row style={{ width: "100%" }}>
      <Col md="12" lg="12">
        <Card>
          <CardBody>
            <div id="accordion">
              {session.map((presentation, index) => {
                return (
                  <Accrordian
                    key={presentation.presentation}
                    presentation={presentation}
                    accordianOpen={accordianOpen}
                  />
                );
              })}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export const Accrordian = ({ presentation, accordianOpen }) => {
  const [quality, setQuality] = useState(720);
  const [isAccordianOpen, setAccordianOpen] = useState(accordianOpen);

  const getSpecificLink = (asset) => {
    return asset[quality].S.toString();
  };
  useEffect(() => {}, [quality]);
  return (
    <Card className="mb-1" key={presentation.presentation}>
      <CardHeader
        className="p-3"
        id="headingOne"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setAccordianOpen(!isAccordianOpen);
        }}
      >
        <h4 style={{ cursor: "pointer" }} className="text-dark">
          {presentation.presentation === "Q"
            ? "Q&A Session"
            : `Presentation ${presentation.presentation}`}
        </h4>
        <Row>
          <Col xl={10}>
            <CardTitle>{presentation?.author}</CardTitle>
            <CardSubtitle className="mb-3">
              {presentation?.description}
            </CardSubtitle>
          </Col>
        </Row>
      </CardHeader>

      <Collapse isOpen={isAccordianOpen}>
        <Card>
          <CardBody className="d-flex justify-content-center">
            <Col
              xl={8}
              md={10}
              xs={12}
              style={{ height: "60vh", width: "100%" }}
              className="d-flex justify-content-center"
            >
              <VideoPlayer
                key={presentation.id}
                presentationId={presentation.id}
                videoUrl={getSpecificLink(presentation.assets)}
                thumbnail={presentation.thumbnails}
                quality={quality}
              />
            </Col>
          </CardBody>

          <CardFooter xl={12} xs={4}>
            <Row>
              <Col xl={2}>
                <label className="col-md-4 px-0 col-form-label">Quality</label>
                <div className="form-group row">
                  <div className="col-md-10">
                    <select
                      className="form-control"
                      onChange={(event) => {
                        setQuality(event.target.value);
                        getSpecificLink(presentation.assets);
                      }}
                    >
                      <option value="360">Low</option>
                      <option value="540">Medium</option>
                      <option value="720" selected>
                        High
                      </option>
                    </select>
                  </div>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Collapse>
    </Card>
  );
};

export default Presentations;
