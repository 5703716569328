import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

import Presentations from '../../components/Presentations'

import sessionList from './data'
import VideoPlayer from 'components/VideoPlayer'
import { useSelector } from 'react-redux'

function Session () {
  const param = useParams()
  const [currentSession, setCurrentSession] = useState([])
  const [introVideo, setIntroVideo] = useState({
    assets: null,
    thumbnails: '',
    quality: 720
  })

  const [sessionDetails, setSessionDetails] = useState({
    title: '',
    day: null,
    description: '',
    chair: '',
    coChair: ''
  })

  const iwaData = useSelector(state => state.Iwa)
  let sessionVideos = []

  useEffect(() => {
    sessionVideos = iwaData?.videos?.filter(
      video => video.session === param.sessionNo
    )
  }, [iwaData])

  useEffect(() => {
    let session = sessionVideos.filter(val => {
      return val.hasOwnProperty('presentation') && val.presentation !== 'Q'
    })
    session.sort((a, b) => a.presentation - b.presentation)

    let qna = sessionVideos.find(val => val.presentation === 'Q')
    if (qna) {
      session.push(qna)
    }

    let intro = sessionVideos.find(videos => {
      return (
        !videos.hasOwnProperty('presentation') || videos.presentation === ''
      )
    })
    console.log(
      session.filter(videos => {
        return (
          videos.hasOwnProperty('presentation') && videos.presentation !== ''
        )
      })
    )
    setIntroVideo(intro)

    setCurrentSession(
      session.filter(videos => {
        return (
          videos.hasOwnProperty('presentation') && videos.presentation !== ''
        )
      })
    )
    // history.go(0);
  }, [iwaData])

  useEffect(() => {
    sessionList.firstWeek.findIndex(day => {
      return day.sessions.find(sessionInfo => {
        if (sessionInfo.sessionNo === Number(param.sessionNo)) {
          setSessionDetails(sessionInfo)
        }
      })
    })
    sessionList.secondWeek.findIndex(day => {
      return day.sessions.find(sessionInfo => {
        if (sessionInfo.sessionNo === Number(param.sessionNo)) {
          setSessionDetails(sessionInfo)
        }
      })
    })
  }, [param.sessionNo])
  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Row className='d-flex align-items-center '>
          <Col xl='12' lg='12' md='12' sm='12'>
            <Breadcrumbs
              title={'Home'}
              breadcrumbItem={'Session ' + param.sessionNo}
            />
          </Col>
        </Row>
        {/* session info */}
        <Card>
          <CardBody>
            <Row className='d-flex align-items-center '>
              <Col xl='6' lg='6' md='6' sm='12'>
                <h2>{sessionDetails?.title}</h2>
                <CardTitle>{sessionDetails?.description}</CardTitle>
                <p className='mt-2 mb-0'>Chair: {sessionDetails?.chair}</p>
                <p className='mb-0'>Co-Chair: {sessionDetails?.coChair}</p>
              </Col>
              <Col
                xl='6'
                lg='6'
                md='6'
                sm='12'
                style={{ height: '50vh', width: '100%' }}
              >
                <IntroVideo
                  introVideo={introVideo}
                  sessionNo={param.sessionNo}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Presentations session={currentSession} />
      </div>
    </div>
  )
}

const IntroVideo = ({ introVideo, sessionNo }) => {
  const [quality, setQuality] = useState(720)
  const getSpecificLink = asset => {
    return asset[quality].S.toString()
  }
  console.log(introVideo)
  return (
    <div style={{ height: '100%', width: '100%' }} id='intro'>
      {!!introVideo?.assets && (
        <React.Fragment>
          <div style={{ height: '88%' }}>
            <VideoPlayer
              key={`intro_video_${sessionNo}`}
              presentationId={sessionNo}
              videoUrl={getSpecificLink(introVideo.assets)}
              thumbnail={introVideo.thumbnails || ''}
              quality={quality}
            />
          </div>
          <select
            className='form-control mt-1'
            onChange={event => {
              setQuality(event.target.value)
              getSpecificLink(introVideo.assets)
            }}
          >
            <option value='360'>Low</option>
            <option value='540'>Medium</option>
            <option value='720' selected>
              High
            </option>
          </select>
        </React.Fragment>
      )}
    </div>
  )
}

export default Session
