import axios from 'axios'
import { setCookies } from 'pages/Authentication/CallBack'
import { GET_IWA_DATA, SET_VIDEOS, SET_POSTERS } from './actionTypes'

let reqBody = new URLSearchParams({
  grant_type: 'refresh_token',
  refresh_token: localStorage.getItem('lwa-ref-tkn'),
  redirect_uri: `${location.protocol + '//' + location.host}/callback`,
  client_id: '1be3b7u433neg4lonscmgl11i'
  // client_secret: "",
})
let options = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}

const getActualTokenFromRefreshToken = () => {
  axios
    .post(
      `https://iwa.auth.eu-west-2.amazoncognito.com/oauth2/token`,
      reqBody,
      options
    )
    .then(res => {
      if (res.data.access_token) {
        localStorage.setItem('lwa-acc-tkn', res.data.access_token)
        localStorage.removeItem('lwa-tkn-exp')
        localStorage.setItem(
          'lwa-tkn-exp',
          new Date(Date.now() + 60 * 60 * 1000 - 300000)
        )
      }
    })
    .then(() => {
      setCookies()
    })
    .catch(err => console.error(err))
}

export const setIwaData = data => ({
  type: SET_VIDEOS,
  payload: data
})

export const getVideos = () => {
  return async dispatch => {
    try {
      const res = await axios.get(
        'https://a3vf3o9y41.execute-api.eu-west-2.amazonaws.com/dev/videos/',
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('lwa-acc-tkn')
          }
        }
      )
      return dispatch({
        type: SET_VIDEOS,
        payload: res.data
      })
    } catch (err) {
      getActualTokenFromRefreshToken()
      console.log(err)
    }
  }
}
export const getPosters = () => {
  return async dispatch => {
    try {
      const res = await axios.get(
        'https://a3vf3o9y41.execute-api.eu-west-2.amazonaws.com/dev/posters/',
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('lwa-acc-tkn')
          }
        }
      )

      let notNullFiles = res?.data.filter(poster => {
        return poster.pdf == 1 || poster.video == 1
      })
      return dispatch({
        type: SET_POSTERS,
        payload: notNullFiles
      })
    } catch (err) {
      getActualTokenFromRefreshToken()
      console.log(err)
    }
  }
}
