import WaterUtilityIcon from '../../assets/images/themeIcons/Water_utility_W.png'
import WasteWaterIcon from '../../assets/images/themeIcons/Wastewater_treatment_W.png'
import DrinkingWaterIcon from '../../assets/images/themeIcons/Drinking_water_W.png'
import CityIcon from '../../assets/images/themeIcons/City_W.png'
import CommunitiesIcon from '../../assets/images/themeIcons/Communities_W.png'
import WaterResourcesIcon from '../../assets/images/themeIcons/Water_resources_W.png'

const sessionList = {
  firstWeek: [
    {
      day: 1,
      date: 'Monday 24 May',
      sessions: [
        {
          sessionNo: 0,
          day: 1,
          title: 'Congress opening ceremony and keynote speech',
          description:
            'Introduction from IWA President Tom Mollenkopf & IWA Executive Director Kala Vairavamoorthy\n* Keynote speech: David Sedlak, Professor, UC Berkeley, United States\n* IWA Awards \n * Introduction to the programme, by co-chairs Eveline Volcke & Jurg Keller',
          chair: `Keynote speaker David Sedlak, Professor, UC Berkeley, United States`,
          coChair: null,
          bgColor: '#d3dbd6',
          icon: null,
          trackName: `Congress opening ceremony and keynote speech`
        },
        {
          sessionNo: 1,
          day: 1,
          title: 'Resource recovery from wastewater',
          description: `The potential of resource recovery and energy generation from wastewater treatment and the need for synergies between technical, operational and political factors in the water and energy sectors`,
          chair: `Yanchen Liu, China`,
          coChair: `Pau Juan-Garcia, United Kingdom`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 2,
          day: 1,
          title: 'Management of climate adaptation',
          description: `Experiences in adaptation to climate change in the water sector.`,
          chair: `Philip De Souza, South Africa`,
          coChair: `Rui Pedro Silvestre Sancho, Portugal`,
          bgColor: '#c7eafb',
          icon: WaterUtilityIcon,
          trackNo: 1,
          trackName: `Water utility management`
        },
        {
          sessionNo: 3,
          day: 1,
          title: 'Utility efficiency and excellence',
          description: `Benchmarking and econometrics that drive water
                        utility operational improvement`,
          chair: `Francisco Cubillo, Spain `,
          coChair: `Yang Villa, Phillipines `,
          bgColor: '#c7eafb',
          icon: WaterUtilityIcon,
          trackNo: 1,
          trackName: `Water utility management`
        },
        {
          sessionNo: 4,
          day: 1,
          title: 'Resource recovery from wastewater',
          description: `Demonstrating the integration of sustainability
with wastewater treatment technologies.`,
          chair: `Sagar Gupta, United States`,
          coChair: `Arifur Rahman, India`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 5,
          day: 1,
          title: 'Strategic water management in cities',
          description: `Using data science and a holistic strategy to better
achieve water planning outcomes at large scale.`,
          chair: `Helena Alegre, Portugal`,
          coChair: `Riddhi Singh, India`,
          bgColor: '#c7eafb',
          icon: WaterUtilityIcon,
          trackNo: 1,
          trackName: `Water utility management`
        },
        {
          sessionNo: 6,
          day: 1,
          title: 'Water quality standards, regulations and economics',
          description: `Economic analysis of municipal water supply with
an alternative approach to the water balance, a
proposal for optimal drinking water quality, and
a potential correlation between magnesium in
drinking water and health risks.`,
          chair: `Shervin Hashemi, South Korea`,
          coChair: `Hayat Raza, Canada`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        }
      ]
    },
    {
      day: 2,
      date: 'Tuesday 25 May',
      sessions: [
        {
          sessionNo: 7,
          day: 2,
          title: 'Resource recovery from wastewater',
          description: `Research on methodologies for the recovery and
reuse of water, energy and nutrients.`,
          chair: `Ana Soares, United Kingdom`,
          coChair: `Prasanna Jogdeo, India`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 8,
          day: 2,
          title: 'Oxidation and other processes',
          description: `Oxidation by ozonation of micropollutants, and
test of anaerobic digested sludge.`,
          chair: `Jorge Rodríguez, United Arab Emirates`,
          coChair: `Ashton Busani Mpofu, South Africa`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 9,
          day: 2,
          title: 'Drinking water Production',
          description: `Insight on treatment technologies for drinking
water production from groundwater sources, such
as biofiltration and softening, focusing on the
removal of manganese, hardness and arsenic.`,
          chair: ` Stephen Foster, United Kingdom`,
          coChair: `Xiaoyuan Zhang, China`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        },
        {
          sessionNo: 10,
          day: 2,
          title: 'Modelling integrated wastewater treatment processes',
          description: `Using mathematical and computer simulative
models to enhance the operation and the
management of wastewater treatment systems.`,
          chair: `How Yong Ng, Singapore`,
          coChair: `Bibilore Omotosho, Nigeria`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 11,
          day: 2,
          title: 'Electrochemical and oxidation processes',
          description: `Insights on the treatment of wastewater using
ozone/UV and electrochemical processes.`,
          chair: ` Olivier Lefebre, Singapore`,
          coChair: `Roberto Paulo de Freitas Negrini, Brazil`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 12,
          day: 2,
          title: 'Drinking water production',
          description: `Introducing a series of treatment technologies
such as coagulation, biofiltration and powder
activated carbon.`,
          chair: `Arslan Ahmad, The Netherlands`,
          coChair: `Gamze Kirim, Canada`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        },
        {
          sessionNo: 13,
          day: 2,
          title: 'Modelling integrated wastewater treatment processes',
          description: `Using mathematical and computer simulative
models to enhance the operation and the
management of wastewater treatment systems.`,
          chair: `Andrea Turolla, Italy`,
          coChair: `Sagar Gupta, India`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 14,
          day: 2,
          title: 'Micropollutants and nanoplastics',
          description: `Insights on the increased amount of
microplastics in wastewater and technologies for
micropollutants removal.`,
          chair: `Chao Chen, China`,
          coChair: `Mbali Sibiya, South Africa`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 15,
          day: 2,
          title: 'Distribution system microbiology and biofilms',
          description: `Characterization of municipal distribution systems,
including observations from chlorinated and
unchlorinated distribution systems`,
          chair: `Regina Sommer, Austria `,
          coChair: `Temple Chukwuemeka Oraek, Nigeria`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        }
      ]
    },
    {
      day: 3,
      date: 'Thursday 27 May',
      sessions: [
        {
          sessionNo: 16,
          day: 3,
          title: 'Emergent contaminants',
          description: `Removal of pollutants, oxidation kinetics, and
water treatment processes.`,
          chair: `Josef Lahnsteiner, Austria`,
          coChair: `Adeolu Adesanya, Nigeria`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 17,
          day: 3,
          title: 'Water utility digital practice',
          description: `Water utilities share their experiences in
implementing digital strategies to improve their
asset management and customer outcomes.`,
          chair: `Peter Dane, The Netherlands`,
          coChair: `Jacob Kwasi Amengor, Ghana`,
          bgColor: '#c7eafb',
          icon: WaterUtilityIcon,
          trackNo: 1,
          trackName: `Water utility management`
        },
        {
          sessionNo: 18,
          day: 3,
          title: 'Potable reuse and alternative drinking water production',
          description: `Overview of the long term experience of potable
reuse in Namibia and operation of UV in the
chloramine process towards potable water reuse.`,
          chair: ` Jan Hofman, United Kingdom`,
          coChair: `Qing Ding, Japan`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        },
        {
          sessionNo: 19,
          day: 3,
          title: 'Emergent contaminants',
          description: `Models of removal of pharmaceuticals in the
environment — treatment in pilot and full-scale
scenarios.`,
          chair: `Val S. Frenkel, United States`,
          coChair: `Pabel Cervantes, Mexico `,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 20,
          day: 3,
          title: 'Becoming a digital water utility of the future',
          description: `Lessons and learnings from water utilites
which have started their journey towards the
digitalisation of their operations.`,
          chair: `Juan Antonio Baeza, Spain`,
          coChair: `Jedrzej Bylka, Poland`,
          bgColor: '#c7eafb',
          icon: WaterUtilityIcon,
          trackNo: 1,
          trackName: `Water utility management`
        },
        {
          sessionNo: 21,
          day: 3,
          title: 'Microbial and chemical risk assessment',
          description: `Microbial risk assessments for drinking water
treatment technologies, including conventional
filtration and ultrafiltration membranes, and a
model proposal for data acquisition to support
antimicrobial resistance risk analysis.`,
          chair: `Daisuke Sano, Japan`,
          coChair: `Georgina Herbert, South Africa`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        },
        {
          sessionNo: 22,
          day: 3,
          title: 'Anammox-based nitrogen removal',
          description: `Experiences with Anammox bacteria for biological
nitrogen removal from wastewater, in various
reactor designs.`,
          chair: `Barth F. Smets, Denmark`,
          coChair: `Innocent Twesigye, Uganda`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 23,
          day: 3,
          title: 'Smart management of wastewater collection systems',
          description: `How smart sensors and equipment can be used to
gain operational insights and improve outcomes in
the management of wastewater and stormwater
collection systems.`,
          chair: `Alessandro Bettin, Italy`,
          coChair: `Niti B. Jadeja, India`,
          bgColor: '#c7eafb',
          icon: WaterUtilityIcon,
          trackNo: 1,
          trackName: `Water utility management`
        },
        {
          sessionNo: 24,
          day: 3,
          title: 'Disinfection techniques in drinking water production',
          description: `By-products of chlorine-based disinfectants
and assessment of UVC-LED for point of use
applications or remote communities. The session is
complemented by a microbiological quantitative risk
assessment on drinking water treatment plants.`,
          chair: `Miller Alonso Camargo-Valero, United Kingdom`,
          coChair: `Junjie Shen, United Kingdom`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        }
      ]
    },
    {
      day: 4,
      date: 'Friday 28 May',
      sessions: [
        {
          sessionNo: 0,
          day: 4,
          title: 'Panel event - Brave Blue World: a perfect storm for water',
          description: `Brave Blue World paints an optimistic picture of how humanity is adopting new technologies and innovations to re-think how water is managed. The film highlights scientific and technological advancements that have been taking place, often behind the scenes, to ensure the world’s population has access to clean water and safe sanitation services and the environment is protected.`,
          chair: `Chair: Sudhir Murthy, CEO - NEWhub Corp *Paul O’Callaghan, CEO & Founder - BlueTech Research and Brave Blue World 

*Norhayyati Abdullah, Associate Director - Universiti Teknologi, Malaysia

*Rose Kaggwa, Director Business and Scientific Services - National Water & Sewerage Corporation, Kampala, Uganda

*Frantz Beznik, R&D Director, Global Head of Sustainable Innovation - Procter & Gamble`,
          coChair: null,
          bgColor: '#d3dbd6',
          icon: null,
          trackName: `Panel event - Brave Blue World: a perfect storm for water`
        },
        {
          sessionNo: 25,
          day: 4,
          title: 'Water reclamation',
          description: `Sustainable initiatives for water reclamation from
various types of wastewater`,
          chair: `Andreas N. Angelakis, Greece `,
          coChair: `Liudmyla Odud, Ukraine `,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 26,
          day: 4,
          title: 'Smart management of water and wastewater networks',
          description: `Improvements in condition assessment and asset
optimisation of water and wastewater networks
through smart sensors and data analytics.`,
          chair: `Joe Dalton, Bahrain`,
          coChair: `Katerina Charalambous, Cyprus`,
          bgColor: '#c7eafb',
          icon: WaterUtilityIcon,
          trackNo: 1,
          trackName: `Water utility management`
        },
        {
          sessionNo: 27,
          day: 4,
          title: 'Antibiotic resistance and biodegradation of pesticides',
          description: `Starts by characterizing antibiotic resistance
genes present in source water for drinking water
production and over different treatment steps.
Pesticide removal is then characterized by two
methods: combined membrane filtration and
biodegradation and microbial co-metabolic
degradation in rapid sand filters of pesticides.`,
          chair: `J Paul Chen, Singapore `,
          coChair: `Maria Alejandra Caicedo Londoño, Colombia`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        }
      ]
    }
  ],
  secondWeek: [
    {
      day: 5,
      date: 'Tuesday 01 June',
      sessions: [
        {
          sessionNo: 0,
          day: 5,
          title: 'Week 2 opening, keynote speeches and IWA awards',
          description: `Keynote speech: Emma Howard Boyd, Chair, Environment Agency, England
*IWA Awards*Keynote speaker - Dr Akinwumi Adesina, President, African Development Bank`,
          chair: null,
          coChair: null,
          bgColor: '#d3dbd6',
          icon: null,
          trackName: `Week 2 opening, keynote speeches and IWA awards`
        },
        {
          sessionNo: 28,
          day: 5,
          title: 'Operation of full-scale WWTP',
          description: `Various operational aspects of full-scale
wastewater treatment plants, including start-up,
greenhous gas emissions and bioaugmentation of
activated sludge.`,
          chair: `Jacek Mąkinia, Poland`,
          coChair: `Jing Sun, China`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 29,
          day: 5,
          title: 'Smart measurements for better decisions',
          description: `How smart tools (meters, sensors, warning
systems) can be used to improve risk
management.`,
          chair: `Matthew Wade, United Kingdom`,
          coChair: `Maxwell Akosah-Kusi, Ghana`,
          bgColor: '#e2d8eb',
          icon: CityIcon,
          trackNo: 4,
          trackName: `City-scale planning & operations`
        },
        {
          sessionNo: 30,
          day: 5,
          title: 'Nature-based solutions and sustainable water management',
          description: `Case studies from Spain and Denmark on solutions
to urban and coastal flooding and a case study on
sustainable water use in industry in Pakistan.`,
          chair: `Albert Guisasola Canudas, Spain`,
          coChair: `Chataigne Djuma Kiza, Democratic Republic
of Congo `,
          bgColor: '#ffedcd',
          icon: WaterResourcesIcon,
          trackNo: 6,
          trackName: `Water resources & large-scale water management`
        },
        {
          sessionNo: 31,
          day: 5,
          title: 'Large wastewater treatment plants',
          description: `Sustainable and innovative initiatives tested at
pilot scale or implemented at full scale at large
wastewater treatment plants around the world`,
          chair: `Kimberly Solon, Belgium`,
          coChair: `Prasanna Jogdeo, India`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 32,
          day: 5,
          title: 'Enhancing urban flood resilience through technology',
          description: `How to use enhancements in technology for better
urban flood resilience.`,
          chair: `Gary Wyeth, Thailand`,
          coChair: `Thor Danielsen, Denmark `,
          bgColor: '#c7eafb',
          icon: WaterUtilityIcon,
          trackNo: 1,
          trackName: `Water utility management`
        },
        {
          sessionNo: 33,
          day: 5,
          title: 'Ecosystems services and understanding water resources',
          description: `How ecosystem services and natural capital
assessment are increasingly being used to support
decision making in relation to water resources.`,
          chair: `Evina Katsou, United Kingdom `,
          coChair: `Kari Elisabeth Fagernaes, Norway`,
          bgColor: '#ffedcd',
          icon: WaterResourcesIcon,
          trackNo: 6,
          trackName: `Water resources & large-scale water management`
        }
      ]
    },
    {
      day: 6,
      date: 'Wednesday 02 June',
      sessions: [
        {
          sessionNo: 34,
          day: 6,
          title: 'Nutrient removal',
          description: `High efficient removal of nutrients (N and/or P)
from wastewater up to low concentrations.`,
          chair: `Hassimi Abu Hasan, Malaysia`,
          coChair: `Michel Caluwé, Belgium `,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 35,
          day: 6,
          title: 'Lifecycle analysis and the circular economy',
          description: `International case studies on the application
of environmental and economic valuation to
resource recovery, the circular economy and wider
water services.`,
          chair: `Mara Ramos, Brazil`,
          coChair: `Saravanamuthu Vigneswaran, Australia`,
          bgColor: '#ffedcd',
          icon: WaterResourcesIcon,
          trackNo: 6,
          trackName: `Water resources & large-scale water management`
        },
        {
          sessionNo: 36,
          day: 6,
          title: 'Distribution system modelling and sustainability',
          description: `Models targeting energy savings and links to the
Sustainable Development Goals with a focus on
trihalomethanes reduction, hydraulic power and
stormwater control.`,
          chair: `Gang Liu, China`,
          coChair: `Ashwani Kumar Tiwari, India`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        },
        {
          sessionNo: 37,
          day: 6,
          title: 'Specific nitrogen removal processes',
          description: `Use of bioreactors for wastewater treatment at
low temperature, denitrification and methane
oxidation`,
          chair: `Yan Zhou, Singapore `,
          coChair: `Eugene Fotso Simo, India`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 38,
          day: 6,
          title:
            'Integrated modelling of nature based solutions towards water-wise cities',
          description: `How digitalisation and nature-based solutions can
work together towards more liveable, sustainable,
smart cities`,
          chair: `Jeroen Langeveld, The Netherlands`,
          coChair: `Palsiri Srirungruang, Thailand `,
          bgColor: '#e2d8eb',
          icon: CityIcon,
          trackNo: 4,
          trackName: `City-scale planning & operations`
        },
        {
          sessionNo: 39,
          day: 6,
          title:
            'Community engagement and collaboration for improved water resource and liveability outcomes',
          description: `Learnings from case studies and literature
to identify important elements for building
community support and beneficial outcomes.`,
          chair: `Joost Buurman, Singapore`,
          coChair: `Anya Eilers, South Africa`,
          bgColor: '#f7dcc6',
          icon: CommunitiesIcon,
          trackNo: 5,
          trackName: `Communities, communication & partnership`
        }
      ]
    },
    {
      day: 7,
      date: 'Thursday 03 June',
      sessions: [
        {
          sessionNo: 40,
          day: 7,
          title: 'Microbiology of WWTP - phosphorus removal',
          description: `Overview of the microorganisms in WWTPs across
the world with focus on PAOs and GAOs.`,
          chair: `Adeline Seak May Chua, Malaysia`,
          coChair: `Mohamed Mahmoud, Egypt`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 41,
          day: 7,
          title:
            'Integrating water management and urban planning with many stakeholders',
          description: `Case studies from Denmark, UK and Belgium on
water management towards a more resilient city.`,
          chair: `Duan Chen, China`,
          coChair: `Neha Agarwa, India`,
          bgColor: '#e2d8eb',
          icon: CityIcon,
          trackNo: 4,
          trackName: `City-scale planning & operations`
        },
        {
          sessionNo: 42,
          day: 7,
          title:
            'Government and industry collaborations for improved water sector resilience',
          description: `Understanding and creating successful
collaborations between governments and industry
as well as between industry players in different
countries. With a focus on the responses to shortand long-term challenges to help improve resilience
against future disasters and emerging threats.`,
          chair: `Katharine Cross, Thailand `,
          coChair: `Emma Weisbord, The Netherlands`,
          bgColor: '#f7dcc6',
          icon: CommunitiesIcon,
          trackNo: 5,
          trackName: `Water resources & large-scale water management`
        },
        {
          sessionNo: 43,
          day: 7,
          title: 'Biosolids management',
          description: `Focus on anaerobic digestions, pretreatment and
dewatering.`,
          chair: `Kwok-Wai Richard Tsang, United States `,
          coChair: `Farokh laqa Kakar, Canada`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 44,
          day: 7,
          title: 'Planning for rehabilitation and retrofitting',
          description: `Smart technologies and decision support
systems to improve wastewater and stormwater
management.`,
          chair: `Jorge Rodriguez, United Arab Emirates`,
          coChair: `Lloyd Fisher-Jeffes, South Africa`,
          bgColor: '#e2d8eb',
          icon: CityIcon,
          trackNo: 4,
          trackName: `City-scale planning & operations`
        },
        {
          sessionNo: 45,
          day: 7,
          title: 'Communication with stakeholders & disaster management',
          description: `Successes and failures of multi-stakeholder
initiatives: pesticide contamination in
groundwater recharge areas, a river restoration
project, and a water supply system.`,
          chair: `Prosun Bhattacharya, Sweden `,
          coChair: `Radoslav Zhekov Tonev, Bulgaria`,
          bgColor: '#fad5e6',
          icon: DrinkingWaterIcon,
          trackNo: 3,
          trackName: `Drinking water & potable use`
        },
        {
          sessionNo: 46,
          day: 7,
          title: 'Biofilm and membrane bioreactors',
          description: `Process optimization examples for biofilm reactors
and membrane bioreactors: comparative study
of membrane bioreactors with and without
activated carbon, process control based on offgas monitoring and biofilm technologies for the
treatment of fish-canning wastewater.`,
          chair: `Tao Guihe, Singapore`,
          coChair: `Nelson Zagabe, Kenya`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 47,
          day: 7,
          title: 'Urban drainage of the future',
          description: `How to obtain water-wise cites through rainwater
reuse and sustainable city planning, with case
studies from Denmark, Norway and the United
Kingdom.`,
          chair: `Pedro Carvalho, Denmark`,
          coChair: `Shotaro Goto, Japan`,
          bgColor: '#e2d8eb',
          icon: CityIcon,
          trackNo: 4,
          trackName: `City-scale planning & operations`
        },
        {
          sessionNo: 48,
          day: 7,
          title: 'Water quality and pollution',
          description: `Remediation of groundwater pollution, fluoride
in groundwater and the fate of sewage pollution
after rainfall events.`,
          chair: `Andrea G. Capodaglio, Italy`,
          coChair: `Palash Sanyal, Canada`,
          bgColor: '#ffedcd',
          icon: WaterResourcesIcon,
          trackNo: 6,
          trackName: `Water resources & large-scale water management`
        }
      ]
    },
    {
      day: 8,
      date: 'Friday 04 June',
      sessions: [
        {
          sessionNo: 49,
          day: 8,
          title: 'Membrane fouling control and lifespan',
          description: `Research conducted in the area of membrane
fouling control and mitigation strategies.`,
          chair: ` Nguyen Bruno, France`,
          coChair: `Michael Johnson, Nigeria `,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 50,
          day: 8,
          title: 'Minimising gaseous emissions',
          description: `Research in the area of minimizing N2O emissions
in wastewater treatment processes and
vaporisation of effluents.`,
          chair: `Teodor Popa, Romania`,
          coChair: `Zhihao Chen, Singapore`,
          bgColor: '#d4ebdd',
          icon: WasteWaterIcon,
          trackNo: 2,
          trackName: `Wastewater treatment & resource recovery`
        },
        {
          sessionNo: 51,
          day: 8,
          title: 'Modelling flood risk and resilience to identify solutions',
          description: `Obtaining resilience of the water sector and improved water management through strategies, plans and assessments`,
          chair: `Dennis Daniel Mwanza, United Kingdom`,
          coChair: `Nilo Nascimento, Brazil`,
          bgColor: '#e2d8eb',
          icon: CityIcon,
          trackNo: 4,
          trackName: `City-scale planning & operations`
        },
        {
          sessionNo: 0,
          day: 8,
          title: 'Closing ceremony',
          description: `The Congress will finish with:
* Announcement of the Poster Award winner
* Young Water Professional panel discussion
* Introduction to the IWA World Water Congress & Exhibition 2022 in Copenhagen`,
          chair: null,
          coChair: null,
          bgColor: '#d3dbd6',
          icon: null,
          trackName: `Closing ceremony`
        }
      ]
    }
  ]
}

export default sessionList
