import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

import { ContextData } from "../../App";

import { Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import profile from "assets/images/profile-img.png";

//Import config
import axios from "axios";
import { OauthSender } from "react-oauth-flow";

const Login = (props) => {
  const [isLogged, setIsLogged, currentUser, setCurrentUser] = useContext(
    ContextData
  );

  const url = ``;

  useEffect(() => {
    localStorage.clear();
    setIsLogged(false);
  }, []);

  // console.log(isLogged)
  // handleValidSubmit
  async function handleValidSubmit(event, values) {
    const body = {
      email: values.email,
      password: values.password,
    };

    let userName = "User Learner";
    localStorage.setItem("authUser", userName);
    localStorage.setItem("brokerId", "12");
    // localStorage.setItem("brokerId", user.Id);
    // setIsLogged(true)
    history.push("/home");
    // await axios
    //   .post(`${url}/broker-login`, body)
    //   .then((res) => {
    //     if (res.data.status) {
    //       setCurrentUser(res.data);
    //       let user = res.data;
    //       let userName = user.firstName + " " + user.lastName;
    //       localStorage.setItem("authUser", userName);
    //       localStorage.setItem("brokerId", user.Id);
    //       // setIsLogged(true)
    //       history.push("/list-company");
    //     } else {
    //       alert("Check Email and Password");
    //     }
    //   })
    //   .catch((err) => console.log(err));
  }

  const history = useHistory();
  return (
    <React.Fragment>
      {/* <RequestAuthorizationCode
        oauthClient={oauthClient}
        render={({ url }) => <a href={url}>Connect to Cognito</a>}
      /> */}
      <div className="home-btn d-none d-sm-block">
        <i className="fas fa-home h2" />
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <center>
            <h3 className="mb-4">IWA - International Water Association</h3>
          </center>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>
                          login to access IWA’s 2021 Digital Congress content
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-4">
                  <div className="p-2">
                    <OauthSender
                      authorizeUrl={`https://iwa.auth.eu-west-2.amazoncognito.com/login`}
                      clientId={`1be3b7u433neg4lonscmgl11i`}
                      redirectUri={`${
                        location.protocol + "//" + location.host
                      }/callback`}
                      // redirectUri={`http://localhost:3000/callback`}
                      args={{
                        scope:
                          "profile openid aws.cognito.signin.user.admin videos/read",
                      }}
                      render={({ url }) => (
                        <a
                          className="btn btn-primary btn-block waves-effect waves-light"
                          href={url}
                        >
                          Login
                        </a>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} IWA - International Water
                  Association
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
