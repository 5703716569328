import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect, matchPath } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      // console.log(props.location.pathname)
      if (isAuthProtected && !localStorage.getItem("lwa-acc-tkn")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      // if (
      //   localStorage.getItem("authUser") !== "super-admin" &&
      //   matchPath(props.location.pathname, { path: "/add-user" })
      // ) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/dashboard", state: { from: props.location } }}
      //     />
      //   )
      // }
      // if (
      //   localStorage.getItem("authUser") !== "super-admin" &&
      //   matchPath(props.location.pathname, { path: "/view-users" })
      // ) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/dashboard", state: { from: props.location } }}
      //     />
      //   )
      // }

      // if (
      //   localStorage.getItem("authUser") !== "super-admin" &&
      //   matchPath(props.location.pathname, { path: "/edit-users/:id" })
      // ) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/dashboard", state: { from: props.location } }}
      //     />
      //   )
      // }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

export default Authmiddleware
