import { SET_VIDEOS, SET_POSTERS } from './actionTypes'

const INIT_STATE = {
  videos: [],
  posters: []
}

const Iwa = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_VIDEOS:
      return {
        ...state,
        videos: [...action.payload]
      }
    case SET_POSTERS:
      return {
        ...state,
        posters: [...action.payload]
      }
    default:
      return state
  }
}

export default Iwa
