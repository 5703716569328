import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

export const setCookies = () => {
  axios
    .get("https://a3vf3o9y41.execute-api.eu-west-2.amazonaws.com/dev/keys", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("lwa-acc-tkn"),
      },
    })
    .then((res) => {
      let keyPairId = res.data["CloudFront-Key-Pair-Id"];
      let policy = res.data["CloudFront-Policy"];
      let signature = res.data["CloudFront-Signature"];

      document.cookie = `CloudFront-Key-Pair-Id=${keyPairId}; domain=.edalytics.com; expires=; path=/`;
      document.cookie = `CloudFront-Policy=${policy}; domain=.edalytics.com; expires=; path=/`;
      document.cookie = `CloudFront-Signature=${signature}; domain=.edalytics.com; expires=; path=/`;
    });
};
function CallBack() {
  const [token, setToken] = useState({});
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const history = useHistory();

  let reqBody = new URLSearchParams({
    grant_type: "authorization_code",
    code: code,
    // redirect_uri: "http://localhost:3000/callback",
    redirect_uri: `${location.protocol + "//" + location.host}/callback`,
    client_id: "1be3b7u433neg4lonscmgl11i",
    client_secret: "",
  });

  let options = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  useEffect(() => {
    axios
      .post(
        `https://iwa.auth.eu-west-2.amazoncognito.com/oauth2/token`,
        reqBody,
        options
      )
      .then((res) => {
        setToken(res.data);
        if (res.data.access_token) {
          localStorage.setItem("lwa-acc-tkn", res.data.access_token);
          localStorage.setItem("lwa-ref-tkn", res.data.refresh_token);
          localStorage.setItem(
            "lwa-tkn-exp",
            new Date(Date.now() + 60 * 60 * 1000 - 300000)
          );
          setCookies();
          history.push("/home");
        } else {
          history.push("/login");
        }
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <div className="container">
      {token.access_token && (
        <div className="d-flex justify-content-center align-items-center">
          <p>Success</p>
        </div>
      )}
    </div>
  );
}

export default CallBack;
