import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { connect } from 'react-redux'
import { Row, Col, Card, CardBody } from 'reactstrap'

import { Link, useHistory, useLocation } from 'react-router-dom'

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown'
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

import megamenuImg from '../../assets/images/megamenu-img.png'
import logo from '../../assets/images/logo.svg'
import logoLightPng from '../../assets/images/logo-light.png'
import logoLightSvg from '../../assets/images/logo-light.svg'
import logoDark from '../../assets/images/logo-dark.png'

// import images
import github from '../../assets/images/brands/github.png'
import bitbucket from '../../assets/images/brands/bitbucket.png'
import dribbble from '../../assets/images/brands/dribbble.png'
import dropbox from '../../assets/images/brands/dropbox.png'
import mail_chimp from '../../assets/images/brands/mail_chimp.png'
import slack from '../../assets/images/brands/slack.png'

//i18n
import { withTranslation } from 'react-i18next'

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
} from '../../store/actions'
import { useEffect } from 'react'
import sessionList from '../../page/learning/data'

const Header = props => {
  const [searchValue, setSearchValue] = useState('')
  const [searchRes, setSearchRes] = useState([])
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [fullscreen, setfullscreen] = useState(false)
  const location = useLocation()
  const [contentType, setContentType] = useState(location.pathname)
  const history = useHistory()

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen () {
    // console.log(!document.fullscreenElement)
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
      setfullscreen(true)
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
      setfullscreen(false)
    }
  }

  const onSearchValueChange = e => {
    setSearchValue(e.target.value)
    let searchResult = []
    sessionList.firstWeek.map(day => {
      return day.sessions.map(session => {
        if (
          session?.title?.toLowerCase()?.includes(searchValue.toLowerCase())
        ) {
          searchResult.push(session)
        }
      })
    })
    sessionList.secondWeek.map(day => {
      return day.sessions.map(session => {
        if (
          session?.title?.toLowerCase()?.includes(searchValue.toLowerCase())
        ) {
          searchResult.push(session)
        }
      })
    })

    setSearchRes(searchResult)
  }
  // function tToggle() {
  //   props.toggleLeftmenu(!props.leftMenu);
  //   if (props.leftSideBarType === "default") {
  //     props.changeSidebarType("condensed", isMobile);
  //   } else if (props.leftSideBarType === "condensed") {
  //     props.changeSidebarType("default", isMobile);
  //   }
  // }
  return (
    <header id='page-topbar'>
      <div className='navbar-header'>
        <div className='d-flex'>
          <div
            className='navbar-brand-box d-flex justify-content-between'
            style={{
              height: '70px',
              width: '310px',
              background: 'transparent'
            }}
          >
            {/* style=
              {{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }} */}
            <Link to='/home' className='logo logo-dark mt-4'>
              <span className='logo-sm '>
                <h5>IWA - International Water Association</h5>
              </span>
              <span className='logo-lg '>
                <h5>IWA - International Water Association</h5>
              </span>
            </Link>
            <Link to='/home' className='logo logo-light mt-1'>
              <span className='logo-sm'>
                <img src={logoLightPng} height='22px' />
              </span>
            </Link>
            <Link to='/home' className='logo logo-light'>
              <span className='logo-lg'>
                <img src={logoLightPng} height='28px' />
              </span>
            </Link>
            <p className='logo logo-light'>
              <span className='logo-lg'>Digital World Water Congress</span>
            </p>
            <p className='logo logo-light'>
              <span className='logo-sm'>Digital World Water Congress</span>
            </p>
          </div>
        </div>

        <div className='d-flex align-items-center'>
          {/* <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
          <form className='app-search d-none d-lg-block'>
            <div className='position-relative'>
              <input
                type='text'
                className='form-control'
                placeholder={'Search ...'}
                value={searchValue}
                onChange={e => onSearchValueChange(e)}
              />
              <span className='bx bx-search-alt'></span>
            </div>
            {!searchValue.length == 0 ? (
              <div
                style={{
                  position: 'absolute',
                  top: '80%',
                  width: '25%',
                  marginTop: '.5em',
                  marginRight: '50%'
                }}
              >
                <Card>
                  <CardBody>
                    <ul className='list-unstyled megamenu-list'>
                      {!searchValue.length == 0 &&
                        searchRes.slice(0, 10).map((res, idx) => (
                          <li
                            key={idx}
                            style={{ cursor: 'pointer', padding: '1em' }}
                            className={idx % 2 === 0 ? 'bg-light' : ''}
                            onClick={() => {
                              res.sessionNo === 0
                                ? history.push(`/ceremonies/${res.day}`)
                                : history.push(`/session/${res.sessionNo}`)

                              setSearchValue('')
                            }}
                          >
                            <h6>{res.title}</h6>
                            <p>by - {res.chair}</p>
                          </li>
                        ))}
                    </ul>
                  </CardBody>
                </Card>
              </div>
            ) : (
              <div></div>
            )}
          </form>
          <div className='dropdown d-flex align-items-center'>
            {/* <select className="form-control mt-1"
              onChange={(e)=> {
                history.push("/" + e.target.value)
                setContentType(e.target.value)
              }}
              value={contentType}
              >
                <option value="home">Programs</option>
                <option value="posters">Posters</option>
              </select> */}
            <button
              className={`btn btn-sm ${
                location.pathname.includes('/home') ||
                location.pathname.includes('session')
                  ? 'btn-primary'
                  : ''
              }  mx-2`}
              onClick={() => history.push('/home')}
            >
              Programs
            </button>
            <button
              className={`btn btn-sm ${
                location.pathname.includes('/posters') ? 'btn-primary' : ''
              }`}
              onClick={() => history.push('/posters')}
            >
              Posters
            </button>
            <button
              type='button'
              onClick={() => {
                toggleFullscreen()
              }}
              className='btn header-item noti-icon waves-effect'
              data-toggle='fullscreen'
            >
              {fullscreen ? (
                <i className='bx bx-exit-fullscreen' />
              ) : (
                <i className='bx bx-fullscreen' />
              )}
            </button>
            <ProfileMenu />
          </div>
          {/* <NotificationDropdown /> */}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
})(withTranslation()(Header))
