import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'

import sessionList from './data'
import { checkTokenExpireTime } from 'App'

import WaterUtilityIcon from '../../assets/images/themeIcons/Water_utility_W.png'
import WasteWaterIcon from '../../assets/images/themeIcons/Wastewater_treatment_W.png'
import DrinkingWaterIcon from '../../assets/images/themeIcons/Drinking_water_W.png'
import CityIcon from '../../assets/images/themeIcons/City_W.png'
import CommunitiesIcon from '../../assets/images/themeIcons/Communities_W.png'
import WaterResourcesIcon from '../../assets/images/themeIcons/Water_resources_W.png'
import {
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Nav,
  NavItem,
  CardBody,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Modal,
  CardHeader
} from 'reactstrap'

import classnames from 'classnames'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { ContextData } from 'App'
import { useDispatch, useSelector } from 'react-redux'
import { getPosters } from 'store/iwa/actions'

const Posters = ({ results }) => {
  const history = useHistory()
  const [showTrackSessionModal, setShowTrackSessionModal] = useState(false)
  const [selectedTrack, setSelectedTrack] = useState(1)
  const dispatch = useDispatch()
  const iwaData = useSelector(state => state.Iwa)

  const themeCards = [
    {
      title: 'Track 1',
      subtitle: 'Water utility management',
      icon: WaterUtilityIcon,
      bgColor: '#c7eafb'
    },
    {
      title: 'Track 2',
      subtitle: 'Wastewater treatment & resource recovery',
      icon: WasteWaterIcon,
      bgColor: '#d4ebdd'
    },
    {
      title: 'Track 3',
      subtitle: 'Drinking water & potable reuse',
      icon: DrinkingWaterIcon,
      bgColor: '#fad5e6'
    },
    {
      title: 'Track 4',
      subtitle: 'City-scale planning & operations',
      icon: CityIcon,
      bgColor: '#e2d8eb'
    },
    {
      title: 'Track 5',
      subtitle: 'Communities, communication & partnership',
      icon: CommunitiesIcon,
      bgColor: '#f7dcc6'
    },
    {
      title: 'Track 6',
      subtitle: 'Water resources & large-scale water management',
      icon: WaterResourcesIcon,
      bgColor: '#ffedcd'
    }
  ]

  const toggleTrackSessionModal = () => {
    setShowTrackSessionModal(!showTrackSessionModal)
    removeBodyCss()
  }

  const getSessionVideos = sessionNo => {
    return results.filter(s => {
      return s.session == sessionNo
    })
  }

  let filtered = []
  sessionList.firstWeek.map(day => {
    return day.sessions.map(x => {
      if (x.trackNo == selectedTrack) {
        filtered.push(x)
      }
    })
  })
  sessionList.secondWeek.map(day => {
    return day.sessions.map(x => {
      if (x.trackNo == selectedTrack) {
        filtered.push(x)
      }
    })
  })

  const removeBodyCss = () => {
    document.body.classList.add('no_padding')
  }

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <Row className='d-flex align-items-center '>
          <Col xl='12' lg='12' md='12' sm='12'>
            <Breadcrumbs title='Home' breadcrumbItem={'Posters'} />
          </Col>
        </Row>
        <Card>
          <CardBody>
            <React.Fragment>
              <Row>
                {themeCards.map((val, index) => {
                  return (
                    <Col md={2}>
                      <Card
                        body
                        style={{
                          background: val.bgColor,
                          height: '100%',
                          userSelect: 'none',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setSelectedTrack(index + 1)
                          setShowTrackSessionModal(true)
                        }}
                      >
                        <img
                          src={val.icon}
                          className='text-left mb-2'
                          height='70px'
                          width='70px'
                        />
                        <CardTitle
                          className='mt-0'
                          style={{
                            fontSize: '1.2rem'
                          }}
                        >
                          {val.title}
                        </CardTitle>
                        <CardText
                          style={{
                            fontSize: '1rem'
                          }}
                        >
                          {val.subtitle}
                        </CardText>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
              <Col sm={6} md={4} xl={8} className='mt-4'>
                <Modal
                  size={'lg'}
                  scrollable={true}
                  toggle={toggleTrackSessionModal}
                  isOpen={showTrackSessionModal}
                  className='modal-dialog-centered'
                >
                  <div className='modal-header'>
                    <h5 className='modal-title mt-0' id='myModalLabel'>
                      Posters belonging to Track {selectedTrack} -{' '}
                      {themeCards[selectedTrack - 1].subtitle}
                    </h5>
                    <button
                      type='button'
                      onClick={() => {
                        setShowTrackSessionModal(!showTrackSessionModal)
                        removeBodyCss()
                      }}
                      className='close'
                      data-dismiss='modal'
                      aria-label='Close'
                    >
                      <span aria-hidden='true'>&times;</span>
                    </button>
                  </div>
                  <div className='modal-body'>
                    <Col md={12}>
                      <Card>
                        <Row>
                          <Col xl='12'>
                            <div className='table-responsive'>
                              <Table className='table  table-hover mb-1  table center table-centered table-nowrap'>
                                <tbody>
                                  {iwaData.posters.length > 0
                                    ? iwaData.posters
                                        .filter(
                                          poster =>
                                            poster.Track == selectedTrack
                                        )
                                        .sort(
                                          (a, b) => Number(a.id) - Number(b.id)
                                        )
                                        .map(poster => {
                                          return (
                                            <>
                                              <tr
                                                style={{
                                                  backgroundColor:
                                                    themeCards[
                                                      selectedTrack - 1
                                                    ].bgColor
                                                  // marginBottom: "25px",
                                                }}
                                              >
                                                <td
                                                  data-toggle='tooltip'
                                                  data-placement='right'
                                                  title={poster.title}
                                                >
                                                  <img
                                                    src={
                                                      themeCards[
                                                        selectedTrack - 1
                                                      ].icon
                                                    }
                                                    height='50px'
                                                    width='50px'
                                                  />
                                                </td>
                                                <th>Poster {poster.id}</th>
                                                <td className='text-wrap'>
                                                  {poster.title}
                                                </td>
                                                <td className='text-right'>
                                                  <button
                                                    onClick={() => {
                                                      history.push({
                                                        pathname: `/posters/${poster.id}`,
                                                        state: {
                                                          // sessionVideos: getSessionVideos(
                                                          //   session.sessionNo
                                                          // )
                                                        }
                                                      })
                                                    }}
                                                    className='btn btn-primary waves-effect waves-light'
                                                  >
                                                    View details
                                                  </button>
                                                </td>
                                              </tr>
                                            </>
                                          )
                                        })
                                    : 'Please wait...'}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      onClick={() => {
                        setShowTrackSessionModal(!showTrackSessionModal)
                        removeBodyCss()
                      }}
                      className='btn btn-secondary waves-effect'
                      data-dismiss='modal'
                    >
                      Close
                    </button>
                  </div>
                </Modal>
              </Col>
            </React.Fragment>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Posters
