import React, { useEffect, useState, Suspense, useContext } from 'react'
import { useLocation, useParams, useHistory, Link } from 'react-router-dom'

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Button
} from 'reactstrap'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

import Presentations from '../../components/Presentations'

import sessionList from './data'
import VideoPlayer from 'components/VideoPlayer'
import { ContextData } from 'App'
import { useSelector } from 'react-redux'

function PosterDetails () {
  const param = useParams()
  let posterData = []
  const [quality, setQuality] = useState(720)

  const getSpecificLink = asset => {
    return asset[quality]?.S?.toString()
  }

  const iwaData = useSelector(state => state.Iwa)
  posterData = iwaData.posters.find(poster => poster.id == param.id)
  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='container-fluid'>
          <Row className='d-flex align-items-center '>
            <Col xl='12' lg='12' md='12' sm='12'>
              <Breadcrumbs
                title={'Posters'}
                breadcrumbItem={'Poster ' + param.id}
              />
            </Col>
          </Row>
          {/* session info */}
          <Card>
            <CardBody>
              <Row className='d-flex align-items-center '>
                <Col
                  xl={posterData?.video === '1' ? '6' : '12'}
                  lg={posterData?.video === '1' ? '6' : '12'}
                  md={posterData?.video === '1' ? '6' : '12'}
                  sm='12'
                >
                  <h2>{posterData?.title}</h2>
                  <CardTitle tag={'p'}>
                    Description : {posterData?.description}
                  </CardTitle>
                  <p className='mt-2 mb-0'>Author: {posterData?.author}</p>
                  {/* <p className='mb-0'>Co-Chair: name</p> */}
                </Col>
                {posterData?.video === '1' && (
                  <Col
                    xl='6'
                    lg='6'
                    md='6'
                    sm='12'
                    style={{ height: '50vh', width: '100%' }}
                  >
                    <div style={{ height: '90%' }}>
                      <VideoPlayer
                        key={`poster_video_${param.id}`}
                        presentationId={param.id}
                        videoUrl={getSpecificLink(posterData?.videolinks || '')}
                        thumbnail={posterData?.thumbnails || ''}
                        quality={quality}
                        style={{ height: '90%' }}
                      />
                    </div>
                    <select
                      className='form-control mt-1'
                      onChange={event => {
                        setQuality(event.target.value)
                        getSpecificLink(posterData?.videolinks)
                      }}
                    >
                      <option value='360'>Low</option>
                      <option value='540'>Medium</option>
                      <option value='720' selected>
                        High
                      </option>
                    </select>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
          {posterData?.pdf === '1' && (
            <Card>
              <CardBody>
                <h4>
                  <i className='bx bxs-file-pdf'></i>
                  {posterData?.filename}
                </h4>
                <a className='btn btn-primary' href={posterData?.pdflinks}>
                  <i className='bx bx-book-open font-size-16 align-middle mr-2'></i>
                  View{' '}
                </a>

                <a
                  href={posterData?.pdflinks}
                  download={posterData?.filename}
                  type='submit'
                  className='mx-2 btn btn-primary'
                >
                  <i className='bx bx-download font-size-16 align-middle mr-2'></i>
                  Download{' '}
                </a>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default PosterDetails
