import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

const Presentations = React.lazy(() => import('../../components/Presentations'))
import VideoPlayer from '../../components/VideoPlayer'

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardSubtitle,
  Collapse,
  CardFooter
} from 'reactstrap'
import sessionList from './data'
import { useSelector } from 'react-redux'

function ExtraVideos () {
  const param = useParams()
  const [currentVideo, setCurrentVideo] = useState([])
  const [sessionDetails, setSessionDetails] = useState({
    title: '',
    description: '',
    chair: '',
    coChair: ''
  })
  const [quality, setQuality] = useState(720)

  const getSpecificLink = asset => {
    return asset[quality]?.S?.toString()
  }

  let iwaData = useSelector(state => state.Iwa)

  useEffect(() => {
    const getCermonyVideo = () => {
      let sessionWithZero = iwaData?.videos?.filter(val => {
        return 0 === Number(val.session) && val.session !== ''
      })

      return sessionWithZero.filter(item => {
        return item.day == param.day
      })
    }
    setCurrentVideo(getCermonyVideo())
  }, [])

  useEffect(() => {
    try {
      let info = sessionList.firstWeek
        .find(days => days.day === Number(currentVideo[0]?.day))
        ?.sessions.find((session, index) => {
          return session.sessionNo === Number(currentVideo[0]?.session)
        })
      setSessionDetails(info)
    } catch (err) {
      let info = sessionList.secondWeek
        ?.find(days => days.day === Number(currentVideo[0]?.day))
        .sessions.find((session, index) => {
          return session.sessionNo === Number(currentVideo[0]?.session)
        })
      setSessionDetails(info)
    }
  }, [currentVideo])
  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='container-fluid'>
          <Row className='d-flex align-items-center '>
            <Col xl='12' lg='12' md='12' sm='12'>
              <Breadcrumbs
                title={'Home'}
                breadcrumbItem={sessionDetails?.title}
              />
            </Col>
            <Row style={{ width: '100%' }}>
              <Col md='12' lg='12'>
                <Card>
                  <CardBody>
                    <div id='accordion'>
                      <Card className='mb-1'>
                        <CardHeader
                          className='p-3'
                          id='headingOne'
                          style={{ cursor: 'pointer' }}
                          // onClick={() => {
                          //   setAccordianOpen(!isAccordianOpen);
                          // }}
                        >
                          <h4
                            style={{ cursor: 'pointer' }}
                            className='text-dark'
                          >
                            {sessionDetails?.title}
                          </h4>
                          <Row>
                            <Col xl={10}>
                              <CardTitle>
                                {sessionDetails?.chair?.split('*').map(v => (
                                  <li>{v}</li>
                                ))}
                              </CardTitle>
                              <CardSubtitle className='m-2'>
                                {sessionDetails?.description
                                  ?.split('*')
                                  .map(v => (
                                    <li>{v}</li>
                                  ))}
                              </CardSubtitle>
                            </Col>
                          </Row>
                        </CardHeader>

                        <Collapse isOpen={true}>
                          <Card>
                            <CardBody className='d-flex justify-content-center'>
                              <Col
                                xl={8}
                                md={10}
                                xs={12}
                                style={{ height: '60vh', width: '100%' }}
                                className='d-flex justify-content-center'
                              >
                                {currentVideo.length > 0 ? (
                                  <VideoPlayer
                                    presentationId={currentVideo[0]?.id || ''}
                                    videoUrl={getSpecificLink(
                                      currentVideo[0]?.assets || ''
                                    )}
                                    thumbnail={
                                      currentVideo[0]?.thumbnails || ''
                                    }
                                    quality={quality}
                                  />
                                ) : (
                                  <h3>Loading...</h3>
                                )}
                              </Col>
                            </CardBody>

                            <CardFooter xl={12} xs={4}>
                              <Row>
                                <Col xl={2}>
                                  <label className='col-md-4 px-0 col-form-label'>
                                    Quality
                                  </label>
                                  <div className='form-group row'>
                                    <div className='col-md-10'>
                                      <select
                                        className='form-control'
                                        onChange={event => {
                                          setQuality(event.target.value)
                                          getSpecificLink(
                                            cermonyVideo[0].assets || ''
                                          )
                                        }}
                                      >
                                        <option value='360'>Low</option>
                                        <option value='540'>Medium</option>
                                        <option value='720' selected>
                                          High
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardFooter>
                          </Card>
                        </Collapse>
                      </Card>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ExtraVideos
