import React from 'react'
import { Redirect } from 'react-router-dom'

// Authentication related pages
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'
import ForgetPwd from '../pages/Authentication/ForgetPassword'

// learning website
import LearningHome from 'page/learning/LearningHome'
import Session from 'page/learning/Session'
import CallBack from 'pages/Authentication/CallBack'
import ExtraVideos from 'page/learning/ExtraVideos'
import Posters from 'page/learning/Posters'
import PosterDetails from 'page/learning/PosterDetails'

const userRoutes = [
  { path: '/', component: LearningHome },
  { path: '/home', component: LearningHome },
  { path: '/session/:sessionNo', component: Session },
  { path: '/ceremonies/:day', component: ExtraVideos },
  { path: '/posters', component: Posters },
  { path: '/posters/:id', component: PosterDetails },

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to='/home' /> }
]

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/callback', component: CallBack },
  { path: '/forgot-password', component: ForgetPwd }
  // { path: "/register", component: Register },
]

export { userRoutes, authRoutes }
